import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Legal.css";

export default function PrivacyPolicy() {
  return (
    <div className="container" id="privacy-policy">
      <h1>DISTANS PRIVACY POLICY</h1>
      <h2>1. INTRODUCTION</h2>
      <p>
        Welcome to distans! In the vast expanse of our world, distans stands as
        a bridge, a connector of communities and those eager to discover them.
        Our ambition is clear - to present you with a streamlined path, letting
        you delve into approved locations and experience the rich tapestry of
        diverse communities right from our app's map. Every check-in, every
        interaction holds a story, and as we help pen this narrative, we remain
        deeply cognizant of the trust you place in us. Your privacy isn't just a
        term; it's a commitment we embrace with utmost sincerity. With every
        location you explore, behind the scenes, we work tirelessly to ensure
        your personal data remains shielded, reflecting the high standards of
        privacy we uphold. Our privacy policy is more than just clauses and
        terms; it's a transparent conversation with you. It speaks of the
        information we gather during your distans journey, the careful ways we
        use this information, and the power you hold over your data. Embarking
        on a journey with distans means stepping into this covenant of trust and
        practice. We urge you to journey through this policy, understanding its
        nuances. And remember, any queries, any concerns - we're just a message
        away. Here's to journeys that are not just explorative but also secure
        and meaningful.
      </p>
      <h2>2. INFORMATION WE COLLECT</h2>
      <p>
        When you embark on your journey with distans, we prioritize creating a
        safe, tailored, and efficient experience. During the registration and
        usage of our app, certain essential information is gathered. For
        instance, your phone number serves as a foundational pillar, ensuring
        that each user is authenticated and verified. This practice is vital as
        it fortifies our platform's security, ensuring an environment exclusive
        to validated users. In our quest to foster meaningful connections, the
        first and last names you provide become an integral component. These
        names get displayed to your friends or acquaintances within the app,
        acting as a bridge to foster genuine interactions. nAdditionally, your
        date of birth isn't just a datum point; it's an assurance. By collecting
        this, we ensure that every user aligns with the necessary age
        requirements, reinforcing a safe and compliant user experience. nOur
        commitment to enhancing your distans journey further extends to app
        permissions. Timely alerts, reminders, and updates are facilitated
        through notification permissions, keeping you abreast of your app
        interactions. Given our app's inherent nature, location access is
        pivotal. This constant location permission ensures that you're
        automatically checked into approved spots, offering a nuanced
        geo-specific experience. Complementing this is the physical activity
        permission, which acts as our sensor to discern your entry or exit from
        locations. It's this finesse that enables the app to flawlessly check
        you in and out, aligning with your movements. Above all, every piece of
        data we collect is not just procedural but intentional, each serving to
        refine your distans journey. Your trust is our responsibility, and
        safeguarding your personal information remains our unwavering
        commitment.
      </p>
      <h2>3. HOW WE USE THE INFORMATION</h2>
      <p>
        At distans, we continuously strive to create a unique and tailored
        experience for every user. The information we collect plays a pivotal
        role in this endeavor. For instance, the location data ensures that you
        are accurately checked into verified locations and aids in facilitating
        automatic check-outs. Such nuances not only enhance the overall user
        experience but also enable us to understand individual usage patterns.
        This comprehension aids us in recommending places that could align with
        your interests, adding a touch of personalization to your exploration
        with distans. Communication remains a cornerstone of our platform. While
        we have minimized the interactions to retain the app's intuitive nature,
        you can expect timely confirmations upon checking into locations. It's
        our way of ensuring you remain informed about your activities within the
        app. As distans continues to grow and evolve, we foresee a potential for
        integrating personalized deals or suggestions based on your check-in
        history. However, any such feature's introduction will always prioritize
        user benefit and uphold the privacy standards we've set. Amidst the
        digital age's connectivity, we acknowledge the unyielding importance of
        privacy. To that end, within the distans platform, unless someone is a
        recognized friend, your name is obfuscated and appears as a mere number,
        safeguarding your identity from unfamiliar eyes. Furthermore, ensuring
        our users enjoy distans both responsibly and safely is of paramount
        importance. The date of birth you provide serves as a means to ascertain
        that you meet the platform's age requirements, reinforcing our
        commitment to a secure and protective environment for all. Your trust
        fuels our dedication. Every piece of information we gather is not just
        for operational efficiency but to refine and enrich your experience,
        always with your safety and privacy as foundational pillars.
      </p>
      <h2>4. DATA SHARING AND DISCLOSURE</h2>
      <p>
        At distans, your privacy isn't just a priority; it's a commitment. We
        deeply value the trust you place in us, and safeguarding your data is
        integral to our operations. Unlike many platforms, we do not share any
        user data with third-party service providers. Every piece of information
        you entrust us with remains strictly within our domain, given that
        distans operates as an independent entity without affiliations to larger
        corporate structures. While the confidentiality of your data is
        paramount, the well-being and safety of our users is an unwavering
        priority. In rare and exceptional circumstances, if an individual's
        safety is at stake, such as in potential kidnapping situations, we would
        permit data sharing with relevant authorities. This is solely to ensure
        the safety of the individual involved.Furthermore, should there ever be
        significant business changes, including mergers or acquisitions, your
        data's sanctity remains unchanged. It will not be shared outside of
        distans and continues to be treated with unwavering respect. Rest
        assured, your personal data or profile details will never be made
        publicly accessible. Our dedication isn't just about bridging
        communities; it's about doing so with integrity, transparency, and an
        unwavering commitment to every user's privacy.
      </p>
      <h2>5. DATA RETENTION</h2>
      <p>
        At distans, the manner in which we handle and retain data is as
        thoughtful as the way we collect it. Our unwavering commitment to your
        privacy can be witnessed in the stringent yet considerate policies we've
        established. When you decide to part ways with our service or delete
        your account, we don't cling onto your data. Instead, we have protocols
        to ensure your personal information is automatically expunged from our
        primary systems. This gesture underpins our respect for your choices and
        our belief in not retaining information beyond its necessity. Yet, the
        narrative of data at distans isn't just about individuals; it's also
        about collective experiences. This is why we might retain insights such
        as the number of users who've checked in during a specific timeframe.
        However, it's paramount to understand that such data is purely aggregate
        in nature, devoid of personal identifiers, preserving the essence of
        anonymity. Diving deeper into the personalized facet of distans, our
        recommendation algorithm stands as a testament to our dedication to
        curate unique experiences for you. To continually refine and perfect
        this algorithm, we do hold onto certain user data. But even here, we've
        set boundaries, ensuring that this data doesn't remain with us beyond a
        two-year window. This conscious decision helps us maintain the delicate
        balance between personalization and privacy. As we tread the path of
        defining our backup protocols, the ethos remains consistent with our
        primary data practices. The tentative blueprint is to retain backup data
        in alignment with our main retention periods, ensuring consistency in
        our commitment to your privacy. As we evolve, the compass guiding us
        remains unaltered - your trust. And with every policy, every decision,
        we strive to fortify that trust, ensuring your privacy is always at the
        forefront.
      </p>
      <h2>6. USER RIGHTS</h2>
      <p>
        At distans, we believe in empowering our users. While we are the
        custodians of your data during your journey with us, it's crucial to
        underline that you remain the true owner. Your rights in relation to
        this data are paramount, and here's how we ensure they are upheld: Every
        piece of information you entrust us with has a purpose, fine-tuning your
        experience. Occasionally, our algorithm may seek validation, like a
        gentle nudge in the form of a push notification, asking if your current
        location aligns with our algorithm's understanding. If ever you spot an
        inaccuracy or wish to access more extensive data insights, we're just an
        email away. While we encourage an open dialogue, you have the
        unequivocal right to request the deletion of your data. An email to our
        dedicated team can initiate this, resulting in a reset of your account,
        ensuring your data's complete removal. Embracing the essence of data
        portability, we also grant you the right to obtain a copy of your data
        in a user-friendly format, allowing you the flexibility to transfer it
        to another service if desired. It's essential to understand the fabric
        of distans. Every data point we request, every permission we seek, is
        intricately woven into the app's functionality. This means while the
        data is integral, it's solely for enhancing your in-app experience,
        devoid of any external marketing ambitions. Given this intricate design,
        certain rights like objection to processing or restriction of processing
        are inherently limited, as each data element ensures the app's seamless
        functioning. Yet, with all these nuances, if there's ever a concern, a
        doubt, or a need for clarity, our 'Contact Us' feature is your direct
        channel to us. We value your trust, and any feedback or complaint will
        be met with diligence and earnestness, reinforcing our commitment to
        safeguarding your rights and privacy.
      </p>
      <h2>7. SECURITY MEASURES</h2>
      <p>
        At distans, while our primary mission is to connect you with
        communities, we also aim to refine and enhance your user experience
        continuously. To achieve this, we occasionally employ specific tools and
        methods to understand usage patterns and cater to individual
        preferences. It's vital to underline that our use of these tools is
        always guided by the utmost respect for user privacy. Local Storage &
        Preferences: To ensure that your distans experience remains consistent
        and personalized, we utilize local storage and shared preferences. These
        tools enable us to remember your settings and preferences, preventing
        the need to set them anew with every session. It's a seamless way to
        make the app truly yours. Engagement Analytics: To better understand how
        our users navigate distans, we might integrate specific analytics tools.
        These tools offer insights into user behavior, allowing us to identify
        popular features, areas for improvement, and overall app performance.
        The data gathered is aggregate, focusing on overall patterns rather than
        individual behaviors. Push Notifications: Every now and then, you might
        receive a notification from us. These push notifications are designed to
        keep you updated, enhance engagement, and offer timely information. Of
        course, the control to opt in or out of these notifications always rests
        with you. Location Services: Given the nature of distans, location
        services play a crucial role. However, any location-specific content or
        feature is employed with discretion, prioritizing your privacy.
        Transparency is a cornerstone of distans. We believe in keeping our
        users informed about every tool and method we employ. If you ever have
        concerns, queries, or need clarity on any aspect, we're always here to
        help. Together, let's ensure that distans remains not just a tool for
        discovery but also a beacon of trust and privacy.
      </p>
      <h2>9. CHILDREN'S PRIVACY</h2>
      <p>
        At distans, we're acutely aware of the importance of protecting the
        privacy of young individuals. The digital landscape is vast and varied,
        and ensuring a safe environment for children is paramount. Given this
        ethos, distans has been designed and structured explicitly for users
        aged 16 and above. We do not knowingly collect, retain, or process
        personal data from individuals under this age threshold. If a user's
        date of birth indicates that they are below 16, they will not be
        permitted to use our platform. If at any point we become aware that we
        have inadvertently collected personal data from a child under the age of
        16, we will take immediate steps to delete such information. As
        guardians, if you ever suspect that your child has provided personal
        data to us without your consent, we urge you to contact us promptly.
        distans stands as a bridge to communities for adults, and in this
        endeavor, the safety and privacy of younger users remain an unwavering
        commitment.
      </p>
      <h2>10. CHANGES TO THIS PRIVACY POLICY</h2>
      <p>
        At distans, our journey of connecting communities is dynamic, evolving
        in tandem with technological advancements, user feedback, and regulatory
        landscapes. As we adapt and grow, there may be instances where our
        Privacy Policy needs updating to accurately reflect our practices and
        commitments. Whenever we make significant changes to this policy, we
        will strive to inform you. This could be in the form of a notification
        within the app, an email update, or any other method of communication
        deemed appropriate. We encourage our users to periodically review our
        Privacy Policy, ensuring they remain informed and comfortable with how
        we protect their personal data. Your continued use of distans following
        any updates signifies your acceptance of the revised policy. We value
        the trust you place in us, and our primary goal will always be to
        maintain and nurture that trust, upholding the highest standards of
        transparency and privacy.
      </p>
      <h2>11. CONTACT INFORMATION</h2>
      <p>
        At distans, open communication forms the bedrock of our relationship
        with users. Your feedback, concerns, questions, or insights are
        invaluable to us, helping shape a more refined and secure experience for
        all.If you wish to discuss any aspect of our Privacy Policy, or have
        other privacy-related questions, our team is always here to assist. You
        can reach out to us via email at daniel@distans.app. For more direct
        communication or urgent matters, feel free to call us at 646-599-2429.
        If you prefer traditional correspondence or need to send us any
        documentation, our physical address is: <br />
        <br />
        43-67 163rd Street
        <br />
        Flushing, NY 11358
        <br />
        <br /> Your trust and confidence in distans are paramount. Rest assured,
        every query or feedback will be addressed with the diligence and respect
        it deserves.
      </p>
    </div>
  );
}
