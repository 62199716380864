import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Legal.css";

export default function TermsOfUse() {
  const copyEmailToClipboard = async (e) => {
    e.preventDefault();
    const email = e.target.innerText;
    try {
      await navigator.clipboard.writeText(email);
      alert("Email address copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy:", err);
      alert("Failed to copy the email address.");
    }
  };
  return (
    <div className="container" id="terms-of-use">
      <h1>DISTANS TERMS OF USE</h1>
      <h2>Welcome to distans</h2>
      <p>
        Thank you for choosing distans, your companion for exploring and
        connecting with places around you. Before you embark on this journey
        with us, we invite you to carefully read these Terms of Use ('ToU'),
        which govern your access to and use of our services. By accessing or
        using distans, you acknowledge that you've read, understood, and agreed
        to be bound by these terms, forming a legally binding contract between
        you and distans.
      </p>
      <h2>Your Agreement with Us</h2>
      <p>
        When you use distans, you enter into a contractual relationship with us,
        affirming that you're at least 16 years of age, as our services are not
        intended for those under 16. Your journey with distans begins by
        registering an account where you'll provide your phone number, verify
        it, and share with us your name and birthday. Rest assured, your name
        remains yours alone to share. It will not be made public unless you
        choose to share it by adding friends to your friends list, fostering a
        community of trust and respect.'
      </p>
      <h2>Navigating the World with distans</h2>
      <p>
        Location (Always): Our app is designed to track your movements
        seamlessly, allowing us to check you into custom markers placed on the
        map. This feature operates in the background, ensuring you can discover
        and be discovered without interruption.'
        <br />
        <br />
        Motion Tracking: We utilize motion tracking to enhance the accuracy of
        our location services, providing you with a richer, more personalized
        experience.'
      </p>
      <p>
        Notifications: To keep you informed and engaged, we'll send you alerts,
        reminders, and updates about your activities and discoveries within
        distans.
      </p>
      <h2>Privacy at the Heart</h2>
      <p>
        Your privacy is paramount. We commit to handling your information with
        the utmost care and protection, guided by our Privacy Policy. By using
        distans, you give us your trust to collect, use, and share your
        information as detailed in the policy, always with the goal of enhancing
        your experience while respecting your privacy.
      </p>
      <h2>Intellectual Property and Your Use of distans</h2>
      <p>
        The content and technologies that make distans what it is — texts,
        graphics, logos, and more — are protected by intellectual property
        rights and belong to us or are used with permission. We share our
        service with you for personal, non-commercial use, and we ask that you
        respect the rights that make distans possible.
      </p>
      <h2>Evolving Together</h2>
      <p>
        The world is in constant motion, and so is distans. We reserve the right
        to modify these ToU to reflect changes in our service, legal landscape,
        or user feedback. Significant changes will be communicated to you in
        advance, and your continued use of distans after such changes signifies
        your acceptance of the updated terms.
      </p>
      <h2>Reach Out to Us</h2>
      <p>
        Your feedback and questions are vital to the growth and improvement of
        distans. Should you wish to reach out to us about these ToU or any
        aspect of your experience, please contact us at&nbsp;
        <button onClick={copyEmailToClipboard}>daniel@distans.app</button>
        .
        <br />
        <br />
        We're here to ensure your journey with distans is enjoyable and
        fulfilling.
      </p>
    </div>
  );
}
