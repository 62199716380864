import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    padding: "0px 50px",
    gap: "50px",
  },
});

export default function Header() {
  const header = useStyles();

  return (
    <header className={header.wrapper}>
      <p>HOME</p>
      <p>LEGAL</p>
      <p>CONTACT</p>
    </header>
  );
}
