import React from "react";
import { Routes, Route } from "react-router-dom";
import { createUseStyles } from "react-jss";
import Header from "./components/layout/Header";
import Home from "./pages/home/Home";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import ContactUs from "./pages/contact/ContactUs";
import TermsOfUse from "./pages/legal/TermsOfUse";

const useStyles = createUseStyles({
  wrapper: {
    height: "100%",
  },
});

export default function AppRouter() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>
  );
}
