import React from "react";
import "./ContactUs.css";

export default function ContactUs() {
  const copyEmailToClipboard = async (e) => {
    e.preventDefault();
    const email = e.target.innerText;
    try {
      await navigator.clipboard.writeText(email);
      alert("Email address copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy:", err);
      alert("Failed to copy the email address.");
    }
  };
  return (
    <div id="contact" className="container">
      <h1>Contact Us!</h1>
      <p>
        If you need to contact us, please email us at:{" "}
        <button onClick={copyEmailToClipboard}>daniel@distans.app</button>
      </p>
    </div>
  );
}
