import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <div id="home">
      <h1>Website Under Construction</h1>
    </div>
  );
}
